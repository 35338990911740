@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerif4-Italic-VariableFont_opsz,wght.ttf")
    format("truetype");
  font-style: italic;
  font-weight: 300 700;
}
@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerif4-VariableFont_opsz,wght.ttf") format("truetype");
  font-style: normal;
  font-weight: 300 900;
}
/* --------------------------- basic Elements ---------------------------- */
:root {
  --color-primary: red;
  --color-grey: white;
  --color-white: white;
  --color-black: black;
  --color-body: #141216;
  --color-articles: #fcfcfc;
  --Swidth: 99vw;
  --Sheight: 88vh;
  
}
body {
  font-family: "SourceSerif";
  background-color: lightgrey;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;


}
header{
  position: relative;
  top: 0px;
  height: auto;
  width: auto;
  background-color: white;
}
header a:hover{
  color: orange;
  cursor: pointer;
}
main {
  position: fixed;
  height: 95vh;
  display: grid;
  width: 100vw;
  grid-template-columns: 1fr;
  transition: all 400ms ease;
  overscroll-behavior: contain;
}
html {
  color: var(--color-body);
  
}
html {
  scroll-behavior: smooth;
}
ul,h1,figure{
  padding: 0px;
  margin: 0px 0px 0px 0px;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
  color: inherit;
}
sup{
  line-height: 0px;
  color: orange;
  font-family: 'SourceSerif';
}
/* --------------------------- endBasicElements ---------------------------- */
#menuSerie{
  --padding : 2vh;
  position: fixed;
  
  width: calc(100vw - 2 * var(--padding));
  height: calc(var(--Sheight) - 2* var(--padding));
  top: 5vh;
  z-index: 2000;
  background-color: var(--color-articles);
  padding: 2vh;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
}
#menuSerie button{
  position: absolute;
  top: 10px;
  right: 10px;
 
}
#menuSerie ul {
  /* display: grid; */
  column-count: 7; 
  column-gap: 15px;
  height: 100%;
  width: auto;
  align-self: center;
  
}
#menuSerie ul li {
  width: calc((var(--Swidth))/7 - 20px);
  cursor: pointer;
  /* border: solid 0.5px black; */
  break-inside: avoid;
  position: relative;
  
}
#menuSerie ul li img {
width: 100%;
height: auto;
object-fit: contain;
}
#menuSerie ul li a {
  position: absolute;
  z-index: 100;
  color: var(--color-articles);
  left: 10px;
  bottom: 10px;
  text-transform: uppercase;
  }
/*---------------------- Recherche --------------------------*/
#menuRecherche{
  position: fixed;
  width:100vw;
  height: 95vh;
  right: 0px;
  top: 5vh;
  z-index: 2000;
  background-color: var(--color-articles);
  visibility: hidden;
  display: none;
  
}
#menuRecherche article{
  width: auto;
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5vh 5% 5vh 5%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

}
#menuRecherche button{
  position: absolute;
  right: 10px;
  background-color: var(--color-articles);
  border-color: transparent;
  font-size: 20px;
 
}
#menuRecherche p {
  margin: 0px;
}
#menuRecherche h1 {
  font-size: 32px;
  font-weight: 400;
}
#listRe{
  background-color: var(--color-articles);
  height: 5vh;
  position:absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border: 1px solid black;
  border-left: none;
  border-width: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}
#listRe ul{
  display: flex;
  flex-direction: row;
  width: auto;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}
#listRe li{
  margin-left: 10px;

}
#listRe a{
  text-decoration: none;
  color: black;
}
#listRe a:hover{
  color: orange;
}
#listRe a:visited{
  color: initial;
}
#menuRecherche section {
  display: grid;
  grid-template-columns: 30% 55%;
  grid-gap: 4%;
  grid-template-rows: auto;
  scroll-margin-top: 20px;

}
#menuRecherche section h1 {
grid-column: 1/2;
grid-row: 1/2;
}
#menuRecherche section figure {
  object-fit: contain;

}
#menuRecherche section figure img{
max-width: 100%;
max-height: 100%;
}
#menuRecherche section figcaption{
  font-size: 11px;
}
.texteRecherche {
  font-family: 'SourceSerif',serif;

  line-height: 1.4em;
  padding: 12vh 4vw 10vh 10px;
}
.textR{
  grid-row: 1/2;
  grid-column: 2/3;

}
.infosR{
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#mapid {
  margin: 0;
  padding: 0;
  height: 100vh;
  /* width: 100vw; */
  background: #d4dadc;
  z-index: 0;
}
.reTitle{
  margin-top: 4vh;
  margin-bottom: 2vh;
}
#biblioContain{
  width: 90vw;
  grid-column: 1/3 ;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 11vh auto;
  column-gap: 5vh;
  row-gap: 2vw;
}
#biblioContain h1 {

  grid-column: 1/3;
  grid-row: 1/2;
}
#imagesLivre {
  padding: 0 5vw;
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  
}
#imagesLivre figure{
  
  max-width: 20vw;
  height: auto;
}
#listeLivre {
  padding: 5vw;
  grid-column: 2/3;
  grid-row: 2/3;
}
#listeLivre li{
  margin-top: 5px;
}
.items {
  z-index: 1;
  background: var(--color-articles);
  /* padding: 2em; */
 height: 88vh;
  overflow-y: scroll;
  /* padding: 0 3em; */
 margin-bottom: 10px;
 
  /* box-shadow: 0 0 0 18px var(--color-white) inset,  0 0 0 20px var(--color-primary) inset; */
}
.items section:first-child{
  margin-top: 3vh;
}
.items .serie section{
  margin-top: 10vh;
}
/* example marker with icon */
.homeIcon:hover svg {
  color: var(--color-primary);
}
.fullBlock {
  
  background: var(--color-primary);
  grid-template-columns: 1fr 0fr;
  animation: fBlock 500ms ease 20ms forwards;
  /* animation-fill-mode: forwards;   */
}
@keyframes fBlock {
  0% {
    grid-template-columns: 1fr 0fr;
  }

  33% {
    grid-template-columns: 1fr 1fr;
  }

  66% {
    grid-template-columns: 1fr 2fr;
  }

  

  100% {
    grid-template-columns: 1fr 3fr;
  }
}
.fullBlock #hideBlock {
  display: block;
}
#hideBlock {
  position: absolute;
  display: none;
  right: 30px;
  top: 20px;
  z-index: 4000;
  background: var(--color-white);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid black;
  box-shadow: none;
}
/* ----------------------------- landing -------------------------------------- */
#landing figure{
  height: auto;
  position: relative;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}
#landing{
 display: none !important;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: black;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#landing video{
  position: fixed;
  padding: 0px;
  width: auto;
  height: 100%;
  z-index: 1100;
  top: 0px;
}
#landing button{
  position: fixed;
  left: 49vw;
  z-index: 1101;
  background-color: transparent;
  transform: rotate(90deg);
  color: white;
  font-size: 30px;
  font-weight: 100;
  border: none;
  bottom: 7vh;

}
button:hover{
  cursor: pointer;
  color: orange;
}
#landing figcaption{
  position: relative;
  align-self: center;
  text-align: center;
  z-index: 1200;
  font-size: 30px;
  color: white;
}
#txtPresKam{

  display: none;
  width: 40vw;
  height: auto;
  align-self: center;
  color: white;
}
.txtPresVis{

  flex-direction: column;
  align-items: center;

}
.txtPresVis > p {text-align: center;}
.txtPresVis > h1 {font-size: 55px;
font-weight: normal;}
.txtPresVis > h2 {font-size: 15px;
font-family: sans-serif;
font-weight: normal;
margin: 0px;}
.invisible{
  visibility: hidden;
  display: none !important;
}
.anim{
  animation-duration: 1s;
  animation-name: fadeout;
}
@keyframes fadeout {
  from {
   opacity:  1;
  }

  to {
    opacity:  0;
  }
}
/* ----------------------------- endLanding -------------------------------------- */
/* ------------------- notes ------------------------- */
#notesContain{
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0px 4vw 0px 10px
}
#notes div{
  margin: 1vh 0px 1vh 0px;
  font-size: 12px;

}
#notes span{
  color: orange;
  
}
#notesPreview{

  position: fixed;
  bottom: 0px;
  right: 15px;
  width: 14vw;
  height: auto;
  max-height: 83vh;
  background-color: var(--color-articles);
  font-size: 12px;
  padding: 10px 20px 25px 20px;
  overflow-y: scroll;
  /* border-top: solid 1px lightgrey; */

}
#notesPreview > div{
display: none;
margin-top: 10px;


}
#notesPreview > div span{
  color: orange;
  
  }
/* ------------------- endNotes ------------------------- */
/* .leaflet-marker-icon.new-york { */
/* color: indianred !important; */
/* display: none; */
/* } */
.hide {
  display: none !important;
}
.leaflet-marker-icon {
  opacity: 0.5;
}
.leaflet-marker-icon.active {
  color: var(--color-primary);
}
.active {
  color: var(--color-primary);
  opacity: 1;
}
/* .markerSection {
  /* display: none; */
/* } */
/* .markerSection.show { */
/* display: block; */
/* } */
.serie.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}
.serieNav{
  width: 96%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: sticky;
  top: -1px;
  background-color: var(--color-articles);
  align-items: center;
  padding-left: 4%;
}
.serieNav ul {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: flex-start;
  margin-top: 1.5em;
  font-size: 0.9em;
 
  
}
.serieNav ul li {
  margin-right: 10px;
}
.serie > h1:nth-child(1) {
  padding: 0 5% ;
  width: 90%;
}
.serie > *:not(*:nth-child(1)){
  padding: 0 3em;
}
.serie {
  display: none;
  min-height: 200vh;
  scroll-behavior: smooth;

}
.serie > nav > h1 {
  /* background: var(--color-white); */
  font-size: 3em;
  position: sticky;
  top: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--color-articles);
  margin-right: 30px;
}
.serie > h1 span {
  text-transform: uppercase;
  font-size: 0.2em;
  letter-spacing: 0.5ch;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
}
.serie.show h1 {
  opacity: 1;
}
.menu {
  position: fixed;
  top: 10vh;
  left: 10vw;
  background: rgb(0, 0, 0, 0.1);
  padding: 1em;
  min-width: 30ch;
  display: none;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3em;
}
.menu.show {
  display: grid;
}
.menu input {
  position: absolute;
  left: -300000px;
}
.menu :checked + label {
  text-decoration: underline;
  font-weight: 800;
  color: black;
}
#showTags {
  position: absolute;
  right: 30px;
  top: 80px;
  z-index: 4000;
  background: var(--color-white);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid black;
  box-shadow: none;
}
.serie > header h1 {
  font-size: 3em;
  font-style: italic;
}
.serie > header #serie-intro {
  font-size: 1.3em;
}
#serie-intro {
  max-width: 90vw;
  
  padding-left: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  
}
#serie-intro h1 {
  font-size: 2em;

}
#serie-intro p {
  font-family: var(--font-sans);
  margin-bottom: 3em;
  hyphens: auto;
  grid-column: 1/2;
  border-left: solid 1px black;
  padding-left: 15px;
}
#serie-intro figure{
  width: 27vw;
  justify-self: center;
  align-self: top;
  grid-row: 1/2;
  grid-column: 2/3;
}
#serie-intro img{
  width: 100%;
}
.ariane {
  position: fixed;
  bottom: 0;
  /* width: 100vw; */
  /* overflow-x: auto; */
  height: 7vh;
  width: 100%;
  /* text-transform: uppercase;*/
  /* font-family: sans-serif; */
  border-top: 1px solid black;
  background: var(--color-grey);
  margin: 0;
  z-index: 10;
}
.ariane img {
  height: 5vh;
  border: 2px solid transparent;
  width: auto;
  object-fit: contain;
}
.ariane ul {
  display: flex;
  gap: 0 1vh;
  margin-top: 0.5vh;
  z-index: 49999;
  list-style-type: none;
}
.ariane img:hover {
  background: lightgrey;
  border: 2px solid black;
}
article {
  overflow-y: auto;
}
article.show {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 90ch;
  padding: 2em;
  background: var(--color-white);
  border-left: 2ch solid var(--color-black);
}
.svg-icon:hover{
  background-color: orange;
}
.indianred .showSomething + article.show {
  display: block;
}
.icons {
  display: none;
}
figure .meta li {
  list-style-type: none;
  margin-bottom: 2em;
}
.meta svg {
  width: 30px;
  height: auto;
}
figure .meta {
  padding: 0;
  margin: 0;
}
figure .meta button:hover {
  color: var(--color-primary);
}
.markerSection article {
  display: none;
}
.markerSection article.show {
  display: block;
}
.closefiche {
  position: absolute;
  left: 2px;
  background: none;
  border: none;
  position: sticky;
  top: 2px;
  width: 50px;
}
.closefiche:hover {
  color: va(--color-primary);
}
.meta-content {
  list-style-type: none;
}
.meta-content li {
  margin-bottom: 3em;
}
.meta-content svg {
  width: 30px;
  position: relative;
}
.caption {
  align-items: end;
}
.items.lock {
  overflow: hidden;
}
#mapid {
  --zoom-start: 20px;
  --zoom-level: 2;
  --zoom-ratio: 1;
  filter: grayscale(100%) invert(100%) contrast(120%) saturate(100%)hue-rotate(100deg);
}
#mapid img[src*="/images/"] {
  width: calc(var(--zoom-start) * (var(--zoom-level) * var(--zoom-ratio)));
  height: auto;
  border: 2ch solid white;
  box-shadow: 0 0 0 0.4ch black;
  transition: all 400ms;
}
#mapid img[src*="/images/"]:hover {
  z-index: 400000 !important;
  position: absolute;
}
.hideMap {
  grid-template-columns: 0 4fr 4fr;
}
#navMenu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 5vh;
  width: 100vw;
  border-bottom: 1px solid black;
  z-index: 10;
}
#navMenu * {
  margin: 0 10px 0 10px;
  align-self: center;
}
/* -------------------- serie ------------------ */
#serie-photo{

  display: grid;
  grid-template-columns: repeat(3,minmax(0,1fr));
  grid-auto-rows: minmax(40vh, auto);
  grid-row-gap: 10vh;
  width: auto;
  overflow: hidden;
  
  /* min-height: 100vh; */
}
#serie-photo figure{
  height: auto;
}
#serie-photo img{
width: 100%;
height: auto;
}
#serie-photo-citation{
  padding: 15px;
  width: 35vw;
  text-align: center;
}
#serie-photo .gauche {

  justify-self: left;
}
#serie-photo .centre{

  justify-self: center;
}
#serie-photo .droite{

  justify-self: right;
}
#serie-photo .petite {

  width: 70%;
  
  
}
#serie-photo .moyenne {

  max-width: 140%;
  max-height: 150%;
  
}
#serie-photo .grande {

  width: 200%;
}
.pleinePage{width: 75vw;
margin-top: 10%;
grid-row: -1}
.haut{
  align-self:flex-start;
}
.bas{
  align-self:flex-end;
}
.milieu{
  align-self:center;
}
#serie-texte{
  margin-top: 30%;
  padding: 0 10%;
}
#serie-texte h1{

  font-size: 2em;
}
#serie-texte h2{

  font-size: 1em;
}
#serie-acteurices{
  background-color: rgb(245, 231, 190, 1);
}
#serie-acteurices h1{
  font-size: 2em;
  padding: 3vw 0px 0px 3vw;
}
#acteuricesContainer{
  column-count: 4;
  column-gap: 20px;
  padding: 0 3vw;
  margin: 4vh 0;
  
}
#acteuricesContainer figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}
#acteuricesContainer figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}
#acteuricesContainer img {
  max-width: 100%;
  display: block;
}
#acteuricesContainer figcaption p{
  margin: 3px 0 0 0;

  font-family: sans-serif;
  line-height: 150%;
}
.ActeuricesRole{
  font-size: 70%;
  color: gray;
}
/* --------------------------------------------- */
/* ---------Preview Container for fiches et image-------- */
#containerCloseBtn{
  position: absolute;
  right: 0px;
  top: 0px;
}
#previewContainer{
  height: 87.7vh;
  width: 75vw;
  background-color: var(--color-articles);
  z-index: 1000;
  position: fixed;
  top: 5.5vh;
  right: 0px;
  overflow: scroll;
}
.ficheContainer{
  width: 94%;
  height: 96%;
  
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: auto;
  grid-gap: 15px;
  padding:2%;

}
.ficheContainer figure{
  width: 100%;
  grid-column: 1;
  grid-row: 1;

}
.ficheContainer figure img{
  width: 100%;
}
.ficheContainer .ficheTags{
  width: 50%;
  grid-column: 1;
  grid-row: 2;
  padding: 10px;
}
.ficheContainer .ficheInfos{
  display: flex;
  flex-direction: column;
  grid-column:2;
}
.ficheInfos h1 {
  width: 80%;
}
.fiche{
  cursor: pointer;
}



